<template>
  <section>
    <header class="overflow-hidden mb-4 rounded-t-0" style="border-radius:46px">
      <input ref="coverInput" type="file" @input="pickCover" accept="image/*" hidden />
      <input ref="profileInput" type="file" @input="pickProfile" accept="image/*" hidden />
      <v-img class="white--text align-end" height="250px"
        :src="clinic.coverPic ? clinic.coverPic : require('@/assets/images/placeholder.png')" :lazy-src="require('@/assets/images/placeholder.png')">
        <v-avatar class="mb-2 ms-2 me-0" color="grey" size="92">
          <v-img :src="clinic.profilePic ? clinic.profilePic : require('@/assets/images/placeholder.png')" :lazy-src="require('@/assets/images/profile-circle.png')"
            class="border border-white border-4 relative">
            <v-btn class="absolute center-xy bg-o70" color="white" elevation="0" fab outlined small
              @click="selectProfile">
              <iconly name="camera" type="light" />
            </v-btn>
          </v-img>
        </v-avatar>
        <v-btn color="white" elevation="0" fab outlined x-large class="absolute center-xy bg-o70" @click="selectCover">
          <iconly name="camera" type="light" size="3x" />
        </v-btn>
      </v-img>
    </header>
    <section>
      <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">

        <span class="my-1 d-block">{{ $t('About') }}</span>
        <v-textarea outlined v-model="clinic.about" type="text" counter="1000" :rules="descriptionRules" required />

        <span class="my-1 d-block">{{ $t('ID') }}</span>
        <v-text-field outlined v-model="clinic.uniqueCode" type="text" :rules="usernameRules" required />

        <span class="my-1 d-block">{{ $t('Phone Number') }}</span>
        <v-combobox outlined single-line v-model="phoneNumbers" :rules="[(v) => !!v]" required multiple append-icon=""
          :delimiters="[',', '-', '.']">
          <template v-slot:selection="data">
            <v-chip small dense :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected"
              @click:close="data.parent.selectItem(data.item)" class="pe-2 ps-4">
              <span class="ltr">{{ data.item }}</span>
              <v-icon class="primary white--text rounded-circle p-1" right small fab>mdi-phone</v-icon>

            </v-chip>
          </template>
        </v-combobox>

        <span class="my-1 d-block">{{ $t('Contact description') }}</span>
        <v-textarea outlined v-model="clinic.contactDescription" type="text" counter="1000" :rules="descriptionRules"
          required />

        <span class="my-1 d-block">{{ $t('Link') }}</span>
        <v-text-field class="ltr" outlined v-model="clinic.link" type="text" :rules="usernameRules" required />

        <span class="my-1 d-block">{{ $t('Address') }}</span>
        <v-textarea outlined v-model="clinic.address" type="text" counter="1000" :rules="descriptionRules" required />

        <span>{{ $t('Map') }}</span>
      </v-form>

      <div style="width: 100%; height: 400px;" class="mb-4 d-block">
        <mapir :apiKey="mapirToken" :center="coordinates" @load="onMapLoaded" @click="mapOnClick" :zoom="13">
          <mapNavigationControl position="top-right" />
          <mapScaleControl position="top-right" />
          <mapMarker :coordinates.sync="markerCoordinates" color="blue" :draggable="true" @dragend="getlatLng">
          </mapMarker>
        </mapir>
      </div>
    </section>

    <v-footer fixed app paddless color="transparent" class="pb-2 pt-0" style="z-index: 5000;">
      <v-container fluid>
        <v-btn color="primary" large block @click="validate()" :loading="submit_loading" :disabled="submit_loading">
          {{ $t('Submit') }}
        </v-btn>
      </v-container>
    </v-footer>
  </section>

</template>

<script>
import {
  mapNavigationControl,
  mapScaleControl
} from "mapir-vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import * as blobUtil from 'blob-util'

export default {
  components: {
    mapNavigationControl,
    mapScaleControl
  },
  data() {
    return {
      map: null,

      submit_loading: false,
      valid: false,

      clinic: {},

      phoneNumbers: "",

      coordinates: [51.420296, 35.732379],
      markerCoordinates: [51.420296, 35.732379],

      mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIn0.eyJhdWQiOiIxNzExNSIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIiwiaWF0IjoxNjQ1MjY3NTI5LCJuYmYiOjE2NDUyNjc1MjksImV4cCI6MTY0NzY4NjcyOSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.sw_1-g4cTn-J__N6nhChXOJVJNVUu8Spm-vemedB0_oz3hvCJTE4oxaCsCxCuRruUVspmgeGKgBgMh4-xtEhrwB_IVuIf5OrJSA1niTccaXGyr6k5VNpg_dHEPstIOJRzAWKsNyzlf2xUK0PkdulfI2AU0dtWFuuPtR12iZEQpZNEwoaOPD1A6tTb58HWBUoUltKSZk96QtoUlND6uuZKKTwaTYBPPwcL7v7JxDlxGEvSkoNWr-R8rzI48MAepXEBSgeewXIcOZ7-EQVHkbhjLyWu4GKJJo9RBqzEYYcVRewNDhqnAJIdPPyzsG7v5-DI2Mbmgo0lUDaD51XWpm2LA",

      titleRules: [
        v => !!v,
        v => (v && v.length <= 100) || this.$t("The maximum length is 100 characters")
      ],

      usernameRules: [
        v => !!v,
        v => (v || '').indexOf(' ') < 0 || this.$t("No spaces are allowed")
      ],

      descriptionRules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters")
      ],

      coverName: null,
      coverExtension: null,
      profileName: null,
      profileExtension: null,

      coverChanged: false,
      profileChanged: false,
    }
  },
  methods: {
    pickCover() {
      let input = this.$refs.coverInput
      let file = input.files
      if (file && file[0]) {

        const lastDot = file[0].name.lastIndexOf('.');
        this.coverName = file[0].name.substring(0, lastDot);
        this.coverExtension = file[0].name.substring(lastDot + 1);

        let reader = new FileReader
        reader.onload = e => {
          this.clinic.coverPic = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])

        this.coverChanged = true;
      }
    },
    pickProfile() {
      let input = this.$refs.profileInput
      let file = input.files
      if (file && file[0]) {

        const lastDot = file[0].name.lastIndexOf('.');
        this.profileName = file[0].name.substring(0, lastDot);
        this.profileExtension = file[0].name.substring(lastDot + 1);

        let reader = new FileReader
        reader.onload = e => {
          this.clinic.profilePic = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])

        this.profileChanged = true;
      }
    },
    selectCover() {
      this.$refs.coverInput.click()
    },
    selectProfile() {
      this.$refs.profileInput.click()
    },

    getClinicDetails(clinicId) {
      apiService.getClinicDetails(clinicId)
        .then((response) => {
          this.clinic = response.data;
          if (this.clinic.phone) {
            this.phoneNumbers = this.clinic.phone.split(' , ');
          }
          this.coordinates = [this.clinic.lon, this.clinic.lat];
          this.markerCoordinates = [this.clinic.lon, this.clinic.lat];
        });
    },
    validate() {
      if (this.profileChanged) {
        this.uploadProfile()
      }
      else if (this.coverChanged) {
        this.uploadCover()
      }
      else
        this.updateClinicInformation();
    },
    updateClinicInformation() {
      this.submit_loading = true;

      this.clinic.lat = this.markerCoordinates[1];
      this.clinic.lon = this.markerCoordinates[0];
      this.clinic.phone = this.phoneNumbers.join(' , ');

      apiService.updateClinicInformation(this.clinic)
        .then((response) => {
          this.submit_loading = false;
          if (response.status == 200) {
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    uploadProfile() {
      var blob = blobUtil.dataURLToBlob(this.clinic.profilePic)
      apiService.upload("PROFILE", blob, this.profileName + "." + this.profileExtension, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then((response) => {
          this.submit_loading = false;
          this.clinic.profilePic = response.data.originalFileDownloadUrl;

          this.profileChanged = false;
          if (this.coverChanged) {
            this.uploadCover()
          }
          else
            this.updateClinicInformation();
        })
        .catch(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: this.$t('Could not upload the file!'),
            confirmButtonText: this.$t("OK"),
          });
        });

    },
    uploadCover() {
      var blob = blobUtil.dataURLToBlob(this.clinic.coverPic)
      apiService.upload("PROFILE", blob, this.coverName + "." + this.coverExtension, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then((response) => {
          this.submit_loading = false;
          this.clinic.coverPic = response.data.originalFileDownloadUrl;
          
          this.coverChanged = false;
          this.updateClinicInformation();
        })
        .catch(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: this.$t('Could not upload the file!'),
            confirmButtonText: this.$t("OK"),
          });
        });

    },
    onMapLoaded(event) {
      var map = event.map;
      setTimeout(() => {
        map.resize();
        map.flyTo({
          center: this.coordinates,
          zoom: 14,
          speed: 0.2,
          curve: 1,
          duration: 5000,
        });
      }, 100);
    },
    async getlatLng(event) {
      var map = event.map;
      this.markerCoordinates = [event.marker._lngLat.lng, event.marker._lngLat.lat]
      await map.flyTo({
        center: this.markerCoordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 2000,
      })

    },
    async mapOnClick(event) {
      // console.log(event.actualEvent.lngLat);
      var map = event.map;
      this.markerCoordinates = [event.actualEvent.lngLat.lng, event.actualEvent.lngLat.lat]
      await map.flyTo({
        center: this.markerCoordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 2000,
      })
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    if (this.$route.params.clinicId) {
      this.getClinicDetails(this.$route.params.clinicId);
    }
    else {
      this.$router.go(-1);
    }
  },
}
</script>
<style scoped>
.center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
</style>